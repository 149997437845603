import * as React from "react";
import "./FeedbackCard.css";
import {IFeedback} from "../types";

function declination(number:number, titles:string[]) {
    let cases = [2, 0, 1, 1, 1, 2];
    return titles[ (number%100>4 && number%100<20)? 2:cases[(number%10<5)?number%10:5] ];
}

const FeedbackCard :React.FC<IFeedback> = ({text,title,date, tariff, points,cargo_weight, category_name, raiting,category_id,onClick}) => {
    let time_left = '10 мин.';
    var a :any = new Date(); // Current date now.
    var b :any = new Date(date); // Start of 2010.
    let result : any = (a - b )/1000 ;
    console.log('result', result);
    let minutes = Math.round(result/60);
    let hour =  Math.floor(result/60/60);
    let days =  Math.floor(result/60/60/24);
    let yeas =  Math.floor(days/365);
    if (yeas >  0) time_left = yeas + declination(yeas , [' год', ' года', ' лет']); else
    if (days >= 1) time_left = days + declination(days , [' день', ' дня', ' дней']);  else
    if (hour >= 1) time_left = hour + declination(hour , [' час', ' часа', ' часов']);   else
        time_left =  minutes + ' мин.';

    const get_category_style =(id:string) => {
        switch (id) {
            case "52b30be1-49d5-11e7-9696-e41f13c2b942" : return "category-500";
            case "bb0df94d-7dde-11e8-81b0-e41f13c2b942" : return "category-1000";
            case "52b30bdd-49d5-11e7-9696-e41f13c2b942" : return "category-1500";
            case "52b30bde-49d5-11e7-9696-e41f13c2b942" : return "category-1500";
            case "52b30bdc-49d5-11e7-9696-e41f13c2b942" : return "category-3000";
            case "52b30bdf-49d5-11e7-9696-e41f13c2b942" : return "category-5000";
            case "52b30be0-49d5-11e7-9696-e41f13c2b942" : return "category-10000";
            case "52b30be2-49d5-11e7-9696-e41f13c2b942" : return "category-10000";
            case "52b30be4-49d5-11e7-9696-e41f13c2b942" : return "category-20000";
        }
        return "category-500";
    };
    const click = () =>{
        if (onClick) onClick();
    };
    const category_class= get_category_style(category_id);
    return (

        <div className="feedback-card" onClick={click}>
            <div className="feedback-card_container" >
                <div className="">
                    <div className="feedback-card_title">
                        <div>{tariff}</div>
                        <div>{time_left} назад</div>
                    </div>
                </div>
                <div className="feedback-card__icon">
                    {points} Адресов
                </div>
                <div className="feedback-card__category-divider"/>
                <div className={ "feedback-card__category all-category " + category_class}>
                    <div className="feedback-card__weight">{cargo_weight} кг</div>
                    <div>{category_name}</div>
                </div>
                <div className="feedback-card__category-divider"/>
                <div className="feedback-card__stars">
                    {
                        Array.from({ length: raiting })
                             .map( (x, indx)=>  <div key={'star_'+indx} >&nbsp;</div>)
                    }
                </div>
                <div className="feedback-card__text">
                    {title}
                </div>
            </div>
        </div>

    )
};

export default FeedbackCard;
