import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {IFeedback} from "./types";
import FeedbackCard from "./FeedbackCard/FeedbackCard";
import "./FeedbackWidget.css";



const FeedbackWidget: React.FC = () =>{
    const scrollSize = 320;
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [isLoadError, setError] = useState<string>('');
    const [feedbackList, setFeedbackList] = useState<IFeedback[]>([]);
    const [isShowLeftArrow, setShowLeftArrow] = useState<boolean>(false);
    const [isShowRightArrow, setShowRightArrow] = useState<boolean>(true);

    const listElement = useRef<HTMLDivElement>();
    const loadFeedbackList = async () => {
        let response = await fetch(`https://api2.tg-group.ru/PublicOrdersAPI/feedback?page=${currentPage}&count=10`);
        if (!response.ok) return setError('Ошибка загрузки отзывов, попробуйте обновить страницу!');
        let json = await response.json();
        if (typeof json == "string") return setError(json);
        if (!json.list) return setError('Ошибка структуры списка отзывов, попробуйте обновить страницу!');
        setFeedbackList( [...feedbackList].concat(json.list));
        setShowRightArrow(json.list.length>0);
    };

    useEffect( () => {
        loadFeedbackList();
    }, [currentPage]);

    const showLeftArrow = () => {
      return  listElement && listElement.current && listElement.current.scrollLeft!=0;
    }
    const showRightArrow = () => {
        if (listElement.current && listElement.current.scrollLeft == 0  && listElement.current.scrollWidth > listElement.current.clientWidth) return  true;
        //@ts-ignore
        console.log(listElement.current.scrollWidth,listElement.current.clientWidth,listElement.current.scrollLeft);
        return listElement && listElement.current && listElement.current.scrollWidth - listElement.current.clientWidth - listElement.current.scrollLeft > 0 ;
    }

    const scrollList = (n:number) => {
        if (listElement && listElement.current) {
            listElement.current.scrollBy(n, 0);
            setTimeout( ()=>{


                setShowLeftArrow(showLeftArrow() as boolean);

                let isRight :boolean = showRightArrow() as boolean;
                if (!isRight) {
                    setCurrentPage(currentPage + 1)
                } else setShowRightArrow(isRight);
            },500)
        }
    };

    return (
        <>

            <div className="feedback-widget">

                <div className="feedback-list" id="feedback-list" ref={ (div: HTMLDivElement) => listElement.current = div}>
                {
                    feedbackList.map((x,indx)=>
                        <FeedbackCard {...x} key={'card_'+indx} onClick={()=>{ window.location.href='/feedback?id='+x.order_id}} />)
                }
                </div>
                <div className="feedback-widget-arrows">
                    {
                        isShowLeftArrow  &&
                        <div className="left_row_block">
                            <div className="b-arrow-left" onClick={() => {
                                scrollList(-1*scrollSize);


                            }}/>
                        </div>
                    }

                        <div className={"right_row_block" + (isShowRightArrow ? '' : ' hidden-arrow')}>
                            <div className="b-arrow-right" onClick={() => {
                                scrollList(scrollSize);
                            }}/>
                        </div>

                </div>
            </div>
        </>
    )
};

export default FeedbackWidget;
