import React from 'react';
import './App.css';
import FeedbackWidget from "./components/FeedbackWidget/FeedbackWidget";

function App() {
  return (
  <>
      <FeedbackWidget/>
  </>
  );
}

export default App;
